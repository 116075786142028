class AuthRequest {
  public email: string;

  public password: string;

  toJson() {
    return {
      email: this.email,
      password: this.password,
    };
  }
}

export default AuthRequest;
